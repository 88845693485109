<template>
	<card>
		<template #header>
			<i @click="$emit('close-customer-edit')" class="dripicons-cross"></i>
		</template>
		<template #body>
			<h2 class="customer-title">{{customer.name}}</h2>
			<section class="tab-header">
				<ul>
					<li @click="showInfoTab"><span class="material-symbols-outlined icons">info</span></li>
					<li @click="showProductTab"><span class="material-symbols-outlined icons">trolley</span></li>
					<li @click="showShortCodeTab"><i class="dripicons-code pointer"></i></li>
					<li><router-link :to="`/billing`"><span class="material-symbols-outlined icons"> monetization_on </span></router-link></li>
				</ul>
			</section>
			<div v-show="infoTab" class="company-info">
				<section class="general-info">
					<div class="tab-title">
						<p>{{ $t('generic-str.general-info') }}</p>
						<router-link
                        :to="`/admin/customers/${customer.id}`"
                        >
						<span class="material-symbols-outlined icon-edit">edit</span>
						</router-link>
					</div>
					<ul>
						<li><span class="material-symbols-outlined icons">domain</span> {{customer.name}}</li>
						<li><span class="material-symbols-outlined icons">language</span></li>
						<li><span class="material-symbols-outlined icons">badge</span> {{customer.tax_id}}</li>
						<li><span class="material-symbols-outlined icons">mail</span> {{customer.billing_email}}</li>
						<li><span class="material-symbols-outlined icons">policy</span> {{customer.antifraud}}</li>
						<li><span class="material-symbols-outlined icons">location_on</span>{{customer.street}}, {{ customer.city }} - {{ customer.province }} </li>
					</ul>
				</section>
				<section class="contract">
					<p>{{ $t('generic-str.contract') }}</p>
					<ul>
						<li><span class="material-symbols-outlined icons"> account_balance_wallet </span> {{customer.balance}}</li>
						<li><span class="material-symbols-outlined icons"> monetization_on </span>{{ customer.currency }}</li>
						<li v-if="customer.type == 'monthly'"><span class="material-symbols-outlined icons"> credit_card</span> Mensal</li>
						<li v-if="customer.type == 'test'"><span class="material-symbols-outlined icons">credit_card</span> Teste</li>
						<li v-if="customer.type == 'prepaid'"><span class="material-symbols-outlined icons">credit_card</span> Pré pago</li>
						<li v-if="customer.type == 'weekly'"><span class="material-symbols-outlined icons">credit_card</span> Semanal</li>
						<li v-if="customer.type == 'biweekly'"><span class="material-symbols-outlined icons">credit_card</span> Quinzenal</li>
						<li><span class="material-symbols-outlined icons">today </span>  {{customer.due_date}}</li>
					</ul>
				</section>

				<section class="responsible">
					<p>{{ $t('generic-str.responsible') }}</p>
					<div v-for="owner in singleCustomer.owners" :key="owner.id" class="card card-contacts relative no-margin">
						<div class="row card-contacts-list-item relative padding-14">
							<div class="col-sm-3">
								<div class="card-contacts-list-avatar">
								<span>{{ owner.name.charAt(0) }}</span>
								</div>
							</div>
							<div class="col-sm-9 card-contacts-list-item-body">
								<h3>{{ owner.name }}</h3>
								<h2 class="hide"></h2>
								<div class="card-contacts-list-item-body-info">
								<div>
									<span class="material-symbols-outlined to-left icon-contact">call</span> <span class="to-left">{{ owner.mobile_number }}</span>
									<div style="clear: both;"></div>
								</div>
								<div>
									<span class="material-symbols-outlined to-left icon-contact">mail</span> <span class="to-left">{{ owner.email }}</span>
									<div style="clear: both;"></div>
								</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div v-show="productTab">
				<div class="create-product">
					<router-link
                      :to="`/admin/customers/${customer.id}/products/create`"
                    >
                      <button
                          type="button"
                          class="btn btn-yup-purple"
                      >
                      {{ $t('generic-str.add') }} {{ $tc('app.plan', 1) }}
                      </button>
                    </router-link>
                </div>
				<section class="products-info">
					<p>{{ $t('app.plan').split(' | ')[1] }}</p>
					<div v-if="!fetchedProducts" class="loading min-h-300">
						<LoadingAnim />
					</div>
					<div v-for="product in products" :key="product.id" class="product-card">
						<ul class="d-flex flex-row justify-content-between">
							<li><span class="material-symbols-outlined">phone_enabled</span> {{ product.name }} </li>
							<li><span class="material-symbols-outlined"> account_balance_wallet </span> {{ product.plan.credits }}</li>
						</ul>
						<ul class="d-flex flex-row justify-content-between m-0">
							<li><span class="material-symbols-outlined">today </span> {{ product.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
							<router-link class="edit"
							:to="`/admin/customers/${customer.id}/products/${product.id}`"
							>
							Editar produto
							<span class="material-symbols-outlined icons">double_arrow</span>
							</router-link>
						</ul>
					</div>
				</section>
			</div>

			<div v-show="shortCodeTab">
				<div class="create-product">
					<router-link
                      :to="`/admin/customers/${customer.id}/short-codes/create`"
                    >
                      <button
                          type="button"
                          class="btn btn-yup-purple"
                      >
                      {{ $t('sms.short-code.add') }}
                      </button>
                    </router-link>
                </div>
				<section class="products-info shortcode-info">
					<p>{{ $t('sms.short-code.info') }}</p>
					<div v-if="!fetchedShortCodes" class="loading min-h-300">
						<LoadingAnim />
					</div>
					<div v-for="shortcode in shortCodes" :key="shortcode.id" class="product-card">
						<ul class="d-flex flex-row justify-content-between">
							<li><span class="material-symbols-outlined">code </span> {{ shortcode.name }}</li>
						</ul>
						<ul class="d-flex flex-row justify-content-between m-0">
							<li><span class="material-symbols-outlined">today </span> {{ shortcode.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
							<router-link class="edit"
							:to="`/admin/customers/${customer.id}/short-codes/${shortcode.id}`"
						>
						Editar produto
							<span class="material-symbols-outlined icons">double_arrow</span>
						</router-link>
						</ul>
					</div>
				</section>
			</div>
		</template>
	</card>
</template>

<script>
import Card from '@/components/Card.vue';
import CustomerService from '@/services/customer.service';
import SmsService from '@/services/admin/sms.service';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';

export default {
  name: 'AdminCustomerEdit',
  components: {
    Card,
    LoadingAnim,
  },
  props: {
    customer: Object,
    singleCustomer: {},
  },
  emits: ['close-customer-edit'],
  data() {
    return {
      infoTab: true,
      productTab: false,
      shortCodeTab: false,
      products: {},
      shortCodes: {},
      fetchedProducts: false,
      fetchedShortCodes: false,
    };
  },
  methods: {
    showProductTab() {
      this.productTab = true;
      this.infoTab = false;
      this.shortCodeTab = false;
      this.fetchProducts();
    },
    showInfoTab() {
      this.productTab = false;
      this.infoTab = true;
      this.shortCodeTab = false;
    },
    showShortCodeTab() {
      this.productTab = false;
      this.infoTab = false;
      this.shortCodeTab = true;
      this.fetchShortCodes();
    },
    fetchProducts() {
      this.$store
        .dispatch('fetchAdminProducts', {
          account_id: this.customer.id,
          page: 1,
        })
        .then((response) => {
          this.products = response.data;
          this.fetchedProducts = true;
        });
    },
    fetchShortCodes() {
      SmsService.getShortCodes({
        account_id: this.customer.id,
      })
        .then((response) => {
          this.shortCodes = response;
          this.fetchedShortCodes = true;
        });
      this.addOverflow();
    },
    addOverflow() {
      if (document.getElementsByClassName('product-card').length > 8) {
        console.log('Overflow ativado');
        document.querySelector('.shortcode-info').classList.add('overflow-vert');//	Adiciona overflow scroll caso tenha muitos elementos.
      }
    },
  },
};
</script>

<style lang="scss" scope>

	.customer-title{
		text-align: center;
	}

	.tab-header ul{
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding-left: 0;
	}

	.contract ul {
		list-style: none;
		text-align: center;
		padding-left: 0;
		display: flex;
		flex-direction: row;
	}

	.tab-header ul li, .contract ul li {
		padding: 1em 1em;
	}

	.tab-header ul li i {
		font-size: 1.5em;
		color: #752DE6;
	}

	.contract ul li i {
		font-size: 1.5em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.general-info ul{
		list-style: none;
		display: flex;
		flex-direction: column;
		padding-left: 0;
	}

	.general-info ul li {
		padding: 0.5em;
		color: #423C4C !important;
		font-weight: 400;
		font-size: 14px;
		display: flex;
		gap: 10px;
		align-items: center;
	}

	.general-info ul li i {
		font-size: 1.5em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.card-contacts {
		margin: 18px;
	}
	.card-contacts-list-item {
		padding: 20px;
	}
	.card-contacts-list-avatar {
		background: #C02FDF;
		width: 65px;
		height: 65px;
		border-radius: 55px;
		text-align: center;
		vertical-align:middle;
		margin: 0 auto;
	}
	.card-contacts-list-avatar span {
		font-size: 25px;
		color: white;
		display: block;
		padding-top: 15px;
	}
	.card-contacts-list-item h3 {
		color: #752DE6;
		font-size: 16px;
		font-weight: 400;
	}
	.card-contacts-list-item h2 {
		color: #423C4C;
		font-size: 14px;
	}
	.card-contacts-list-item-body span{
		color: #423C4C;
		font-size: 14px;
		display: block;
		margin-top: 5px;
		font-weight: 400;
	}
	.card-contacts-list-item-body-info {
		font-size: 13px;
		color: #423C4C;
		margin-top: 10px;
	}

	.to-left {
		float: left !important;
	}

	.icon-contact {
		font-size: 18px !important;
		margin-right: 5px !important;
		margin-top: 6px !important;
	}

	.card-header .dripicons-cross {
		margin: 0 95%;
		cursor: pointer;
		font-size: 1.5rem;
	}

	.pointer {
		cursor: pointer;
	}

	.products-info ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		padding-left: 0;
	}

	.products-info ul li{
		color: #423C4C;
		font-size: 14px;
		font-weight: 400;
		display: flex;
		align-items: center;
		gap: 3px;
	}

	.products-info  p{
		color: #423C4C;
		font-size: 16px;
		font-weight: 400;
	}

	.products-info ul li i {
		font-size: 1.2em;
		color: #752DE6;
		vertical-align: middle;
		margin-right: 5px;
	}

	.product-card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1rem;
		padding: 12px;
		border: 1px solid #E5E5E5;
		border-radius: 8px;
	}

	.product-card i {
		vertical-align: middle;
	}

	.create-product {
       display: flex;
       justify-content: center;
       margin: 1em;
	}

	.tab-title {
       display: flex;
       justify-content: space-between;
	}

	.tab-title p{
		font-size: 16px;
		color: #423C4C;
		font-weight: 400;
	}

	.no-margin {
		margin: 0 !important;
	}

	.padding-14 {
		padding: 14px !important;
	}

	.overflow-vert {
		overflow-y: scroll;
		max-height: 30.5em
	}

	.icon-edit{
		color: #667085;
	}

	.edit{
		display: flex;
		align-items: center;
		color: #6B23DC;
		font-size: 13px;
		font-weight: 600;
	}

	.material-symbols-outlined{
		color: #667085;
	}

	.icons{
		color: #6B23DC;
		cursor: pointer;
	}

	.icon-date-code{
		font-weight: 800px;
	}
	.contract ul li{
		display: flex;
		align-items: center;
		gap: 5px;
	}
</style>
