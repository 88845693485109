<template>
  <div>
    <CardComponent ref="card" variant="tertiary">
      <template #header>
        Lista de clientes
        <ul class="actions top-right">
          <li>
            <a href="javascript:void(0)" @click="fetchCustomers(1)">
              <span class="material-symbols-outlined"> refresh </span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="showFilter">
              <span class="material-symbols-outlined"> filter_list </span>
            </a>
          </li>
        </ul>
      </template>
      <router-link :to="{ name: 'CreateCustomer' }" class="add-cliente-responsive">
        <button>{{ $t('customer-component.add-client') }} <span
          class="material-symbols-outlined icons"> add_circle </span></button>
      </router-link>
      <TableComponent :fields="fields" :items="customers" :busy="loading">
        <template #cell(name)="slotProps">
          <AvatarComponent :name="slotProps.item.name" :image="slotProps.item.profile_picture"></AvatarComponent>
          {{ slotProps.item.name }}
        </template>
        <template #cell(status)="slotProps">
          <BadgeComponent v-if="slotProps.data === 'active'" variant="success">Ativo</BadgeComponent>
          <BadgeComponent v-else-if="slotProps.data === 'closed'" variant="warning">Inativo</BadgeComponent>
          <BadgeComponent v-else variant="danger">Suspenso</BadgeComponent>
        </template>
        <template #cell(actions)="slotProps">
          <dropdown>
            <template v-slot:text>
              <span class="material-symbols-outlined">
                more_horiz
              </span>
            </template>
            <template v-slot:items>
              <a
                role="button"
                class="dropdown-item"
                @click="toggleCustomerEdit(slotProps.item)"
              >
                {{ $tc('app.more-details', 1) }}
              </a>
              <router-link
                :to="{ name: 'CustomerEdit', params: { customerId: slotProps.item.id } }"
                class="dropdown-item"
              >
                {{ $t('app.edit') }}
              </router-link>
              <a
                role="button"
                v-if="allowedToDestroy(slotProps.item.id)"
                class="dropdown-item"
                @click="destroy(slotProps.item.id)"
              >
                {{ $t('app.delete') }}
              </a>
            </template>
          </dropdown>
        </template>
      </TableComponent>
      <PaginationComponent v-model="currentPage" :total-pages="totalPages"
                           @change="fetchCustomers"></PaginationComponent>
      <modal
        ref="plan-modal"
        :title="$t('generic-str.new-plan')"
        @submit="store"
      >
      </modal>
    </CardComponent>
    <SidebarComponent
      ref="filter"
      :title="$tc('app.filter', 2)"
      class="filter-client"
      :filters="filters"
      @filter="applyFilter"
      backdrop
    >
      <div class="d-flex flex-column gap-2 pb-2">
        <p>{{ $tc('contacts-component.filter_description') }}</p>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <InputComponent :label="$t('app.account_name')" v-model="filter.name"
                        ref="searchInput"></InputComponent>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <InputComponent label="CNPJ" v-model="filter.tax_id" ref="searchInputCnpj"></InputComponent>
      </div>
      <div class="d-flex flex-column gap-2 pb-2">
        <SelectComponent
          :options="statuses"
          v-model="filter.status"
          :label="$t('app.status')"
        ></SelectComponent>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between">
          <ButtonComponent @click="cleanFilter" variant="secondary">{{
              $t("app.clean")
            }}
          </ButtonComponent>
          <ButtonComponent @click="handleFilterAction">{{ $t("app.apply") }} {{
              $tc("app.filter", 2)
            }}
          </ButtonComponent>
        </div>
      </template>
    </SidebarComponent>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import Dropdown from '@/components/Dropdown.vue';
import Modal from '@/components/Modal.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import AvatarComponent from '@/components/ui/AvatarComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';

export default {
  name: 'AdminPlansCard',
  components: {
    PaginationComponent,
    BadgeComponent,
    AvatarComponent,
    CardComponent,
    Dropdown,
    Modal,
    SidebarComponent,
    SelectComponent,
    ButtonComponent,
    InputComponent,
    TableComponent,
  },
  data() {
    return {
      fields: [
        'name',
        'tax_id',
        'status',
        { key: 'updated_at', formatter: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss') },
        'actions',
      ],
      filters: [
        {
          name: 'name',
          type: 'text',
          value: '',
        },
        {
          name: 'tax_id',
          type: 'text',
          value: '',
        },
      ],
      loading: true,
      plans: [],
      customers: [],
      currentPage: 1,
      totalPages: 1,
      form: {
        name: '',
        tax_id: '',
      },
      clickable: true,
      filter: {
        name: '',
        tax_id: '',
        status: '',
      },
      statuses: [{
        text: `${this.$tc('app.active')}`,
        value: 'active',
      }, {
        text: `${this.$tc('app.inactive')}`,
        value: 'closed',
      }, { text: `${this.$tc('app.suspended')}`, value: 'suspended' }],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetchCustomers();
  },
  methods: {
    toggleCustomerEdit(item) {
      this.$emit('toggle-customer-edit', item);
    },
    fetchCustomers(page = this.currentPage) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchAdminCustomers', { page, ...this.filter })
        .then((response) => {
          this.customers = response.data;
          this.totalPages = response.last_page;
          this.loading = false;
        });
    },
    allowedToDestroy(customerId) {
      return (
        this.$store.state.auth.user.role === 'owner' &&
        this.$store.state.account.id !== customerId
      );
    },
    handleFilterAction() {
      this.applyFilter(this.filter);
    },
    applyFilter(filters) {
      this.fetchCustomers(1);
      this.$refs.filter.toggle();
    },
    store() {
      this.loading = true;
      this.$store.dispatch('storeAdminPlans', this.form).then((response) => {
        this.fetch();
      });
    },
    update(planId) {
      this.loading = true;
      this.$store
        .dispatch('updateAdminPlans', {
          planId,
          input: this.form,
        })
        .then((response) => {
          this.fetch();
        });
    },
    destroy(customerId) {
      this.loading = true;
      Swal.fire({
        title: this.$t('adm.customer.remove'),
        text: this.$t('adm.customer.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('destroyCustomer', customerId)
            .then((response) => {
              this.fetchCustomers(1);
            });
        } else {
          this.loading = false;
        }
      });
    },
    showFilter() {
      this.$refs.filter.toggle();
    },
    cleanFilter() {
      this.filter = {
        name: '',
        tax_id: '',
        status: '',
      };
      this.handleFilterAction();
    },
  },
};
</script>

<style scoped lang="scss">

.filter-client {
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: #101828 !important;
  }

  input {
    width: 100% !important;
  }

  .form-check {
    width: 40% !important;
  }

  .form-check-label {
    color: #707070;
    font-weight: 500;
    cursor: pointer;
  }

  .form-check-input {
    cursor: pointer;
    width: 18px;
    height: 18px;
    background-color: #f0f0f2;
    border: 1px solid #d6dadd;
    border-radius: 2px;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: var(--form-input);
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-right: 15px !important;

    span {
      color: var(--gray-font-color);
    }
  }

  .data {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #b7b7b7;
    opacity: 60%;
    padding: 20px;
    color: #707070;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 22px;
    cursor: pointer;
  }

  input[type="date"]::placeholder {
    font-weight: 500;
    color: #707070;
    cursor: pointer;
  }

  .buscar {
    width: 420px;
    height: 50px;
    border-radius: 6px;
    border: 1.5px solid #b7b7b7;
    opacity: 60%;
    padding: 20px;
  }

  .buscar::placeholder {
    color: #707070;
    font-weight: 500;
    cursor: pointer;
  }
}

th .add-cliente-router button {
  width: 100%;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #6B23DC;
  font-size: 16px;
}

.icons {
  color: #6B23DC !important;
  cursor: pointer;
}

.material-symbols-outlined {
  color: #667085;
}

.badge {
  font-size: 14px !important;
}

.add-cliente-responsive {
  display: none;
}

@media (max-width: 1200px) {

  .add-cliente-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add-cliente-responsive button {
    background-color: var(--clr-yup-purple) !important;
    color: white;
    border: var(--clr-yup-purple);
    border-radius: 6px;
    display: flex;
    padding: 0.5rem;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }

  .icons {
    color: white !important;
    cursor: pointer;
  }
}
</style>
