<template>
  <div class="content">
    <PageHeader
      :title="`${$t('generic-str.menu.management')} Yup`"
      :subtitle="$t('customer-component.customer-list')"
    />
    <section v-if="hidden" class="d-flex justify-content-center p-2 mb-4">
      <div class="card-div d-flex justify-content-between">
        <div class="d-flex flex-column m-3 card-itens">
          <p> {{ $t('customer-component.welcome-message-page-client') }} </p>
          <router-link :to="{ name: 'CreateCustomer' }" class="add-cliente-router"
            ><button>Add {{$t('app.customer')}}</button></router-link
          >
        </div>
        <div class="d-flex justify-content-end card-itens2">
          <img
            class="gif-airplane"
            src="../../../../public/assets/img/animation-airplane.gif"
            alt=""
          />
          <span
            class="material-symbols-outlined close"
            id="close-button"
            @click="hideCard"
          >
            close
          </span>
        </div>
      </div>
    </section>
    <section class="page-content container-fluid">
      <div class="row">
        <div :class="showCustomerEdit ? 'col-md-8 col-sm-12' : 'col-12'">
          <admin-customers-card ref="plans" @toggle-customer-edit="toggleCustomerEdit" />
        </div>
        <Transition name="slide-fade">
          <div
            v-show="showCustomerEdit"
            :class="{ 'col-md-4 col-sm-12': showCustomerEdit }"
          >
            <admin-customer-toggle
              @close-customer-edit="closeCustomerEdit"
              :customer="customerEditData"
              :singleCustomer="customer"
            />
          </div>
        </Transition>
      </div>
    </section>
    <SidebarComponent ref="viewCustomers" backdrop>
      <div
        v-if="loading || !currentClient || currentClient.length === 0"
        class="p-4 text-center d-flex justify-content-center"
      >
        <b-spinner label="Spinning" class="spinner-color"></b-spinner>
      </div>
      <div v-else>
        <h2 class="customer-title">{{ currentClient.name }}</h2>
        <section class="tab-header">
          <ul>
            <li
              @click="showInfoTab"
              :class="['icons-top', isActive('info') ? 'active' : '']"
            >
              <span class="material-symbols-outlined icons">info</span>
            </li>
            <li
              @click="showProductTab"
              :class="['icons-top', isActive('product') ? 'active' : '']"
            >
              <span class="material-symbols-outlined icons">shopping_bag</span>
            </li>
            <li
              @click="showShortCodeTab"
              :class="['icons-top', isActive('shortCode') ? 'active' : '']"
            >
              <i class="dripicons-code pointer"></i>
            </li>
            <li
              @click="showInvoicesTab"
              :class="['icons-top', isActive('invoices') ? 'active' : '']"
            >
              <span class="material-symbols-outlined icons">monetization_on</span>
              <!-- <router-link :to="`/billing`" class="icons-fatura">
              </router-link> -->
            </li>
          </ul>
        </section>

        <div v-show="infoTab" class="company-info">
          <section class="general-info">
            <div class="tab-title">
              <p>{{ $t("generic-str.general-info") }}</p>
              <router-link :to="`/admin/customers/${currentClient.id}`">
                <span class="material-symbols-outlined icon-edit">edit</span>
              </router-link>
            </div>
            <ul>
              <li>
                <span class="material-symbols-outlined icons">domain</span>
                {{ currentClient.name || "-" }}
              </li>
              <li><span class="material-symbols-outlined icons">language</span>-</li>
              <li>
                <span class="material-symbols-outlined icons">badge</span>
                {{ currentClient.tax_id || "-" }}
              </li>
              <li>
                <span class="material-symbols-outlined icons">mail</span>
                {{ currentClient.billing_email || "-" }}
              </li>
              <li>
                <span class="material-symbols-outlined icons">policy</span>
                {{ currentClient.antifraud || "-" }}
              </li>
              <li>
                <span class="material-symbols-outlined icons">location_on</span>
                {{
                  currentClient.street
                    ? `${currentClient.street}, ${currentClient.city} - ${currentClient.province}`
                    : "-"
                }}
              </li>
            </ul>
          </section>
          <section class="contract">
            <p>{{ $t("generic-str.contract") }}</p>
            <ul>
              <li>
                <span class="material-symbols-outlined icons">
                  account_balance_wallet
                </span>
                {{ currentClient.balance }}
              </li>
              <li>
                <span class="material-symbols-outlined icons"> monetization_on </span
                >{{ currentClient.currency }}
              </li>
              <li v-if="currentClient.type == 'monthly'">
                <span class="material-symbols-outlined icons"> credit_card</span> Mensal
              </li>
              <li v-if="currentClient.type == 'test'">
                <span class="material-symbols-outlined icons">credit_card</span> Teste
              </li>
              <li v-if="currentClient.type == 'prepaid'">
                <span class="material-symbols-outlined icons">credit_card</span> Pré pago
              </li>
              <li v-if="currentClient.type == 'weekly'">
                <span class="material-symbols-outlined icons">credit_card</span> Semanal
              </li>
              <li v-if="currentClient.type == 'biweekly'">
                <span class="material-symbols-outlined icons">credit_card</span> Quinzenal
              </li>
              <li>
                <span class="material-symbols-outlined icons">today </span>
                {{ currentClient.due_date }}
              </li>
            </ul>
          </section>

          <section class="responsible">
            <p>{{ $t("generic-str.responsible") }}</p>
            <div
              v-for="owner in currentClient.owners"
              :key="owner.id"
              class="card card-contacts relative no-margin"
            >
              <div class="row card-contacts-list-item relative padding-14">
                <div class="col-sm-3">
                  <div class="card-contacts-list-avatar">
                    <span>{{ owner.name.charAt(0) }}</span>
                  </div>
                </div>
                <div class="col-sm-9 card-contacts-list-item-body">
                  <h3>{{ owner.name }}</h3>
                  <h2 class="hide"></h2>
                  <div class="card-contacts-list-item-body-info">
                    <div>
                      <span class="material-symbols-outlined to-left icon-contact"
                        >call</span
                      >
                      <span class="to-left">{{ owner.mobile_number }}</span>
                      <div style="clear: both"></div>
                    </div>
                    <div>
                      <span class="material-symbols-outlined to-left icon-contact"
                        >mail</span
                      >
                      <span class="to-left">{{ owner.email }}</span>
                      <div style="clear: both"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div v-show="productTab">
          <section class="products-info">
            <div class="create-product">
              <router-link :to="`/admin/customers/${currentClient.id}/products/create`">
                <button type="button" class="btn btn-yup-purple">
                  {{ $t("generic-str.add") }} {{ $tc("app.plan", 1) }}
                </button>
              </router-link>
            </div>
            <p>{{ $t("app.plan").split(" | ")[1] }}</p>
            <div
              v-if="!fetchedProducts"
              class="p-4 text-center d-flex justify-content-center"
            >
              <b-spinner label="Spinning" class="spinner-color"></b-spinner>
            </div>
            <!-- <div v-for="product in products" :key="product.id" class="product-card">
              <ul class="d-flex flex-row justify-content-between">
                <li><span class="material-symbols-outlined">phone_enabled</span> {{ product.name }} </li>
                <li><span class="material-symbols-outlined"> account_balance_wallet </span> {{ product.plan.credits }}</li>
              </ul>
              <ul class="d-flex flex-row justify-content-between m-0">
                <li><span class="material-symbols-outlined">today </span> {{ product.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
                <router-link class="edit"
                :to="`/admin/customers/${currentClient.id}/products/${product.id}`"
                >
                Editar produto
                <span class="material-symbols-outlined icons">double_arrow</span>
                </router-link>
              </ul>
            </div> -->
            <div
              v-if="
                fetchedProducts && (!currentAccPlans || currentAccPlans.data.length === 0)
              "
              class="p-4 text-center"
            >
              <LottieAnimNoData />
              <p>{{$t('app.no-data-for-query')}}</p>
            </div>
            <template v-if="currentAccPlans && fetchedProducts">
              <div
                v-for="currentAccPlan in currentAccPlans.data"
                :key="currentAccPlan.id"
                class="product-card"
              >
                <ul class="d-flex flex-row justify-content-between">
                  <li>
                    <span class="material-symbols-outlined">phone_enabled</span>
                    {{ currentAccPlan.name }}
                  </li>
                  <!-- <li><span class="material-symbols-outlined"> account_balance_wallet </span> 10000</li> -->
                </ul>
                <ul class="d-flex flex-row justify-content-between m-0">
                  <li>
                    <span class="material-symbols-outlined">today </span>
                    {{ formatDate(currentAccPlan.created_at) }}
                  </li>
                  <!-- <router-link class="edit"
                :to="`/admin/customers/${currentClient.id}/products/${product.id}`"
                >
                Editar produto
                <span class="material-symbols-outlined icons">double_arrow</span>
                  </router-link> -->
                  <router-link
                    class="edit"
                    :to="`/admin/customers/${currentClient.id}/plans`"
                  >
                    {{ $t("app.edit") }}
                    <span class="material-symbols-outlined icons">double_arrow</span>
                  </router-link>
                </ul>
              </div>
            </template>
          </section>
        </div>

        <div v-show="shortCodeTab">
          <!-- <div class="create-product">
            <router-link
                        :to="`/admin/customers/${currentClient.id}/short-codes/create`"
                      >
                        <button
                            type="button"
                            class="btn btn-yup-purple"
                        >
                        {{ $t('sms.short-code.add') }}
                        </button>
                      </router-link>
                  </div>
          <section class="products-info shortcode-info">
            <p>{{ $t('sms.short-code.info') }}</p>
            <div v-if="!fetchedShortCodes" class="loading min-h-300">
              <LoadingAnim />
            </div>
            <div v-for="shortcode in shortCodes" :key="shortcode.id" class="product-card">
              <ul class="d-flex flex-row justify-content-between">
                <li><span class="material-symbols-outlined">code </span> {{ shortcode.name }}</li>
              </ul>
              <ul class="d-flex flex-row justify-content-between m-0">
                <li><span class="material-symbols-outlined">today </span> {{ shortcode.created_at | date('DD/MM/YYYY HH:mm:ss') }}</li>
                <router-link class="edit"
                :to="`/admin/customers/${currentClient.id}/short-codes/${shortcode.id}`"
              >
              Editar produto
                <span class="material-symbols-outlined icons">double_arrow</span>
              </router-link>
              </ul>
            </div>
          </section> -->
          <section class="products-info shortcode-info">
            <div class="create-product">
              <router-link
                :to="`/admin/customers/${currentClient.id}/short-codes/create`"
              >
                <button type="button" class="btn btn-yup-purple">
                  {{ $t("sms.short-code.add") }}
                </button>
              </router-link>
            </div>
            <p>{{ $t("sms.short-code.info") }}</p>
            <div
              v-if="!fetchedShortCodes"
              class="p-4 text-center d-flex justify-content-center"
            >
              <b-spinner label="Spinning" class="spinner-color"></b-spinner>
            </div>
            <div
              v-if="
                fetchedShortCodes &&
                (!currentShortCodes || currentShortCodes.data.length === 0)
              "
            class="p-4 text-center">
              <LottieAnimNoData />
              <p>{{$t('app.no-data-for-query')}}</p>
            </div>
            <template v-if="currentShortCodes && fetchedShortCodes">
              <div
                v-for="(currentShortCode, i) in currentShortCodes.data"
                :key="currentShortCode.id + i"
                class="product-card"
              >
                <ul class="d-flex flex-row justify-content-between list-unstyled">
                  <li>
                    <span class="material-symbols-outlined">phone_enabled</span>
                    {{ currentShortCode.name }}
                  </li>
                </ul>
                <ul class="d-flex flex-row justify-content-between m-0 list-unstyled">
                  <li>
                    <span class="material-symbols-outlined">today </span>
                    {{ formatDate(currentShortCode.created_at) }}
                  </li>
                  <router-link class="edit" to="/sms/short-codes">
                    {{ $t("app.edit") }}
                    <span class="material-symbols-outlined icons icons-arrow">double_arrow</span>
                  </router-link>
                </ul>
              </div>
            </template>
          </section>
        </div>
        <div v-show="invoicesTab">
          <section class="products-info shortcode-info">
            <div class="invoices-new">
              <p>{{ $tc("app.invoice", 2) }}</p>
              <router-link :to="`/billing`" class="icon-fatura">
                <span class="material-symbols-outlined">open_in_new</span>
              </router-link>
            </div>
            <div
              v-if="!fetchedInvoices"
              class="p-4 text-center d-flex justify-content-center"
            >
              <b-spinner label="Spinning" class="spinner-color"></b-spinner>
            </div>
            <div
              v-if="fetchedInvoices && (!currentInvoices || currentInvoices.length === 0)"
              class="p-4 text-center"
            >
              <LottieAnimNoData />
              <p>{{$t('app.no-data-for-query')}}</p>
            </div>
            <template v-if="currentInvoices && fetchedInvoices">
              <div class="invoice-card" v-for="(currentInvoice, i) in currentInvoices.data" :key="currentInvoice.id + i">
                <ul class="list-unstyled">
                  <li>
                    <button
                      type="button"
                      class="id-tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="currentInvoice.id"
                    >
                      ID: {{ currentInvoice.id }}
                    </button>
                  </li>
                </ul>
                <ul class="list-unstyled">
                  <li>
                    <span class="material-symbols-outlined icons-invoice">schedule</span>
                    {{ currentInvoice.due_date }}
                  </li>
                </ul>
                <ul class="list-unstyled">
                  <li>
                    <span class="material-symbols-outlined icons-invoice">monetization_on</span>
                    {{ currentInvoice.total || 'Valor não disponível' }}
                  </li>
                </ul>
                <ul class="list-unstyled badge badge-success">
                  <li>{{ currentInvoice.status }}</li>
                </ul>
                <a v-if="currentInvoice.url" target="_blank" :href="currentInvoice.url">
                  <span class="material-symbols-outlined icon-invoice">barcode_scanner</span>
                </a>
              </div>
            </template>
          </section>
        </div>
      </div>
    </SidebarComponent>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import Swal from 'sweetalert2';
import CustomerService from '@/services/customer.service';
import PageHeader from '@/components/PageHeader.vue';
import AdminCustomersCard from '@/components/cards/AdminCustomersCard.vue';
import AdminCustomerToggle from '@/components/cards/AdminCustomerToggle.vue';
import SidebarComponent from '@/components/SidebarComponent.vue';
import SmsService from '@/services/sms.service';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
// import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// import Pagination from '@/components/PaginationComponent.vue';

const webApi = axios();

export default {
  name: 'Customers',
  components: {
    PageHeader,
    AdminCustomersCard,
    AdminCustomerToggle,
    SidebarComponent,
    LottieAnimNoData,
    // Pagination,
    // LoadingAnim,
  },
  props: {
    singleCustomer: {},
  },
  emits: ['close-customer-edit'],
  data() {
    return {
      showCustomerEdit: false,
      customerEditCard: 'col-4',
      customerEditData: {},
      fetched: false,
      customers: [],
      customer: [],
      searchValue: '',
      hidden: false,
      loading: true,
      form: {
        name: '',
        tax_id: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
      selectedCustomers: [],
      infoTab: true,
      productTab: false,
      shortCodeTab: false,
      invoicesTab: false,
      products: {},
      shortCodes: {},
      fetchedProducts: false,
      fetchedShortCodes: false,
      fetchedInvoices: false,
      currentClient: {},
      currentAccPlans: {},
      currentShortCodes: {},
      currentInvoices: {},
      activeTab: null,
      tableFields: [
        {
          key: 'id',
        },
        {
          key: 'total',
        },
        {
          key: 'status',
          localize: true,
        },
        {
          key: 'due_date',
        },
      ],
      departments: [],
      currentPage: 1,
      totalPages: 1,
      fullscreen: false,
      q: '',
      page: 1,
      users: [],
      editingUser: null,
      message: '',
    };
  },
  created() {
    this.fetch(1);
  },
  watch: {
    site() {
      this.hideElement();
    },
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    this.$root.$on('UsersCardReload', () => {
      this.fetch();
    });
  },
  methods: {
    fetchAccountShortCode() {
      this.fetchedShortCodes = false;
      console.log('fetchAccountShortCode:: ', this.$store);
      webApi
        .get('/sms/short_codes?name=&page=1')
        .then(
          (response) => {
            console.log('Short Code:: ', response.data);
            this.currentShortCodes = response.data;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedShortCodes = true;
        });
    },
    fetchAccountPlans(id) {
      console.log('fetchAccountPlans:: ', this.$store);
      webApi.get(`/admin/plans?account_id=${id}&page=1`).then(
        (response) => {
          console.log('Planos:: ', response.data);
          this.currentAccPlans = response.data;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    toggleCustomerEdit(item) {
      this.loading = true;
      console.log('Teste', item);
      this.$refs.viewCustomers.toggle();
      // this.showCustomerEdit = !this.showCustomerEdit;
      this.customerEditData = item;
      CustomerService.getCustomer(this.customerEditData.id).then(
        (response) => {
          this.loading = false;
          console.log('Click response: ', response);
          this.currentClient = response || {};
          this.fetchAccountPlans(response.id);
        },
        (error) => {
          console.log('Request error:', error);
          this.loading = false;
          this.currentClient = {};
        },
      );
    },
    closeCustomerEdit() {
      this.showCustomerEdit = false;
    },
    fetch(page) {
      this.loading = true;
      const search = this.searchValue.trim();
      // Vanilla javascript regex -> search.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,'\$1.\$2.\$3\/\$4\-\$5');
      const taxID = search.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5',
      );
      const isTaxID = taxID !== search && !Number.isNaN(parseInt(taxID, 10));
      this.form.page = page;
      this.fetched = false;
      if (isTaxID || this.searchValue.length === 18) {
        this.form.name = '';
        this.form.tax_id = taxID;
      } else {
        this.form.tax_id = '';
        this.form.name = search;
      }
      CustomerService.getCustomers(this.form).then(
        (response) => {
          this.loading = true;
          this.fetched = true;
          this.customers = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('adm.customer.remove'),
        text: this.$t('adm.customer.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.fetched = false;
          CustomerService.removeCustomer(id).then(
            () => {
              this.fetch(this.form.page);
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('webhook-component.remove.deleted'),
                type: 'success',
              });
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    showProductTab() {
      this.setActiveTab('product');
      this.productTab = true;
      this.infoTab = false;
      this.shortCodeTab = false;
      this.invoicesTab = false;
      this.fetchProducts();
    },
    showInfoTab() {
      this.setActiveTab('info');
      this.productTab = false;
      this.infoTab = true;
      this.shortCodeTab = false;
      this.invoicesTab = false;
    },
    showShortCodeTab() {
      this.setActiveTab('shortCode');
      this.productTab = false;
      this.infoTab = false;
      this.shortCodeTab = true;
      this.invoicesTab = false;
      // this.fetchShortCodes();
      this.fetchAccountShortCode();
    },
    showInvoicesTab() {
      this.setActiveTab('invoices');
      this.productTab = false;
      this.infoTab = false;
      this.shortCodeTab = false;
      this.invoicesTab = true;
      this.fetchInvoices();
    },
    fetchProducts() {
      this.fetchedProducts = false;
      this.$store
        .dispatch('fetchAdminProducts', {
          account_id: this.customer.id,
          page: 1,
        })
        .then(
          (response) => {
            this.products = response.data;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetchedProducts = true;
        });
    },
    fetchShortCodes() {
      this.loading = true;
      SmsService.getShortCodes({
        account_id: this.customer.id,
      }).then((response) => {
        this.shortCodes = response;
        this.fetchedShortCodes = true;
        this.loading = false;
      });
      this.addOverflow();
    },
    addOverflow() {
      if (document.getElementsByClassName('product-card').length > 8) {
        console.log('Overflow ativado');
        document.querySelector('.shortcode-info').classList.add('overflow-vert'); //	Adiciona overflow scroll caso tenha muitos elementos.
      }
    },
    hideCard() {
      this.hidden = true;
    },
    hideElement() {
      if (!this.site || this.site.value === '') {
        this.$refs.listItem.style.display === 'none';
      } else {
        this.$refs.listItem.style.display = '';
        this.$refs.listItem.innerHTML = `<span class='material-symbols-outlined icons'>${this.site}</span>`;
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    isActive(tab) {
      return this.activeTab === tab;
    },
    showModalInfo(item) {
      console.info('$emit:UsersModal', item);
      this.$root.$emit('UsersModal', item);
      this.$refs['users-modal'].showHandler('users-modal', item);
    },
    fetchInvoices(id) {
      this.fetchedInvoices = false;
      console.log('Fetching invoices for client ID:', id);
      webApi.get(`/admin/invoices?client_id=${id}&page=1`)
        .then((response) => {
          console.log('Invoices response:', response.data);
          this.currentInvoices = response.data;
          this.fetchedInvoices = true;
        })
        .catch((error) => {
          console.error('Error fetching invoices:', error);
          this.fetchedInvoices = false;
        });
    },
    updateUser() {
      this.updating = true;
      this.$store
        .dispatch('updateUser', this.editingUser)
        .then((response) => {
          this.fetch();
        })
        .finally(() => {
          this.updating = false;
        });
    },
    editUser(user) {
      this.editingUser = {
        id: user.id,
        email: user.email,
        department: user.departments.lenght ? user.departments[0].id : '',
        is_agent: user.pivot.is_agent,
        max_calls_at_time: user.pivot.max_calls_at_time,
        name: user.name,
        role: user.pivot.role,
      };
      this.$refs['edit-user'].show();
    },
    search(q) {
      // console.log('What arrives: ', q);
      this.q = q;
      this.fetch(1);
    },
    /* search() {
      console.log('What arrives: ', this.qSearch);
      this.$emit('search', this.qSearch);
    }, */
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch();
          });
        }
      });
    },
    changePage(page) {
      /* this.page = page;
      this.$emit('change-page', this.page); */
      this.fetch(page);
    },
    converToUUID(str) {
      if (!str) return '';
      str = this.insertString(str, 8, '-');
      str = this.insertString(str, 13, '-');
      str = this.insertString(str, 18, '-');
      str = this.insertString(str, 23, '-');

      return str.toLowerCase();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped lang="scss">
td a {
  color: inherit;
}
.block-el {
  min-height: 300px;
}
.btn-remove {
  color: #fff;
  margin-bottom: 5px;
}
.btn-new {
  margin-bottom: 5px;
}

.id-tooltip {
  background-color: transparent;
  border: none;
  color: var(--gray);
}
.color-white {
  color: #fff;
}
.not-found a {
  color: var(--gray);
}
.form-search {
  float: right;
  width: 100%;
}
.form-search button {
  border: solid 1px #ccc;
  border-left: 0px;
  text-align: center;
  border-radius: 0px 10px 10px 0px;
  height: 35px;
}
.form-search button i {
  font-size: 16px;
}
.w-100 {
  width: 100% !important;
}
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.card-div {
  width: 80%;
  background-color: #42b9bc;
  border: 1px solid #42b9bc;
  border-radius: 8px;
  margin: 1rem;
}

.card-div p {
  font-size: 24px;
  color: #fff;
}

.card-div button {
  width: 20vh;
  height: auto;
  font-size: 16px;
  background: none;
  color: #fff;
  border: 1.5px solid #fff;
  border-radius: 10px;
  font-weight: 200;
  padding: 12px 2rem;
}

.add-cliente-router {
  width: 20vh;
}

.card-div .gif-airplane {
  width: 80%;
  height: auto;
}

.card-itens {
  width: 60%;
  padding: 16px;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.close {
  width: 24px;
  height: 24px;
  color: #ffffff !important;
  margin: 20px 30px 0 0;
  opacity: 1;
  cursor: pointer;
}

.hidden {
  display: none;
}

.customer-title {
  text-align: center;
}

.tab-header ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}

.contract ul {
  list-style: none;
  text-align: center;
  padding-left: 0;
  display: flex;
  flex-direction: row;
}

.tab-header ul li,
.contract ul li {
  padding: 1em 1em;
}

.tab-header ul li i {
  font-size: 1.5em;
  color: #752de6;
}

.contract ul li i {
  font-size: 1.5em;
  color: #752de6;
  vertical-align: middle;
  margin-right: 5px;
}

.general-info ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.general-info ul li {
  padding: 0.5em;
  color: #423c4c;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.general-info ul li i {
  font-size: 1.5em;
  color: #752de6;
  vertical-align: middle;
  margin-right: 5px;
}

.card-contacts {
  margin: 18px;
}
.card-contacts-list-item {
  padding: 20px;
}
.card-contacts-list-avatar {
  background: #c02fdf;
  width: 65px;
  height: 65px;
  border-radius: 55px;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
}
.card-contacts-list-avatar span {
  font-size: 25px;
  color: white;
  display: block;
  padding-top: 15px;
}
.card-contacts-list-item h3 {
  color: #752de6;
  font-size: 16px;
  font-weight: 400;
}
.card-contacts-list-item h2 {
  color: #423c4c;
  font-size: 14px;
}
.card-contacts-list-item-body span {
  color: #423c4c;
  font-size: 14px;
  display: block;
  margin-top: 5px;
  font-weight: 400;
}
.card-contacts-list-item-body-info {
  font-size: 13px;
  color: #423c4c;
  margin-top: 10px;
}

.to-left {
  float: left !important;
}

.icon-contact {
  font-size: 18px !important;
  margin-right: 5px !important;
  margin-top: 6px !important;
}

.card-header .dripicons-cross {
  margin: 0 95%;
  cursor: pointer;
  font-size: 1.5rem;
}

.pointer {
  cursor: pointer;
}

.products-info ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.products-info ul li {
  color: #423c4c;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 3px;
}

.products-info p {
  color: #423c4c;
  font-size: 16px;
  font-weight: 400;
}

.products-info ul li i {
  font-size: 1.2em;
  color: #752de6;
  vertical-align: middle;
  margin-right: 5px;
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 12px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin: 1rem 0;
}

.product-card i {
  vertical-align: middle;
}

.create-product {
  display: flex;
  justify-content: center;
  margin: 1em;
}

.invoice-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 0.3rem;
  padding: 10px 5px 10px 3px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin: 1rem 0;
}

.invoice-card ul,
.invoice-card a {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
}

.invoice-card a span {
  font-size: 20px;
  cursor: pointer;
}

.invoice-card ul {
  max-width: 25%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.invoice-card ul li {
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.id-tooltip {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

.tab-title {
  display: flex;
  justify-content: space-between;
}

.tab-title p {
  font-size: 16px;
  color: #423c4c;
  font-weight: 400;
}

.no-margin {
  margin: 0 !important;
}

.padding-14 {
  padding: 14px !important;
}

.overflow-vert {
  overflow-y: scroll;
  max-height: 30.5em;
}

.invoices-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoices-new p {
  margin: 0;
}

.icon-edit {
  color: #667085;
}

.edit {
  display: flex;
  align-items: center;
  color: #6b23dc;
  font-size: 13px;
  font-weight: 600;
}

.icon-fatura span {
  display: flex;
  align-items: center !important;
}

.material-symbols-outlined {
  color: #667085;
}

.icons {
  color: #6b23dc;
  cursor: pointer;
}

.icons-invoice {
  font-size: 18px;
}

.icons-top,
.icons-fatura,
.dripicons-code {
  padding: 0 !important;
  margin: 1rem !important;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.icons,
.dripicons-code {
  font-size: 24px;
  line-height: 1;
}

.icons-top:hover {
  background-color: #c4a7f2;
  border: 5.5px solid#ddcdf8;
  transform: scale(1.2);
}

.dripicons-code {
  color: inherit;
}

.icons-top.active,
.icons-fatura.active,
.dripicons-code.active {
  background-color: #ddcdf8;
  border: 5.5px solid #ddcdf8;
  transform: scale(1.2);
}

.icon-date-code {
  font-weight: 800px;
}
.contract ul li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.spinner-color {
  color: #b690f1;
  width: 2rem;
  height: 2rem;
  border-width: 3px;
}

.material-symbols-outlined.icon-invoice {
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  display: inline-block;
  cursor: pointer;
}

.material-symbols-outlined.icon-invoice:hover {
  transform: translateY(-5px);
}

.edit {
  display: flex;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  align-items: center;
  cursor: pointer;
}

.edit:hover {
  transform: translateX(5px);
}

@media screen and (max-width: 960px) {
  .card-itens {
    width: 70% !important;
    padding: 0;
  }

  .card-itens p {
    font-size: 12px;
  }

  .card-itens button {
    width: 10vh;
    font-size: 10px;
    padding: 5px;
    border: 0.5px solid #fff;
    border-radius: 5px;
  }

  .close {
    margin: 20px 40px 0 0;
    font-size: 20px;
  }

  .card-itens2 {
    width: 80%;
    height: auto;
  }

  .card-div .gif-airplane {
    width: 80%;
    height: 200px;
  }
}

@media screen and (max-width: 576px) {
  .card-itens p {
    font-size: 8px;
    width: 100%;
    margin: 0;
  }

  .card-itens button {
    width: 8vh;
    font-size: 8px;
    padding: 4px;
    border: 0.5px solid #fff;
    border-radius: 5px;
  }

  .card-div .gif-airplane {
    width: 100% !important;
    height: auto;
  }

  .card-div {
    align-items: center;
    justify-content: center;
  }

  .card-itens {
    width: 60%;
    padding: 0;
  }

  .close {
    margin: 10px 0;
  }

  .card-itens2 {
    width: 40%;
    height: 100%;
    display: flex;
  }
}

@media screen and (max-width: 500px) {
  .invoice-card {
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  padding: 16px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin: 1rem 0;
}

.invoice-card ul,
.invoice-card a {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-card a span {
  font-size: 20px;
  cursor: pointer;
}

.invoice-card ul li {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.invoice-card ul {
  max-width: 100%;
}

.id-tooltip {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

@media screen and (max-width: 400px) {
  .card-itens p {
    font-size: 8px;
    width: 100%;
    margin: 0;
  }

  .card-itens button {
    width: 8vh;
    font-size: 8px;
    padding: 4px;
    border: 0.2px solid #fff;
    border-radius: 5px;
  }

  .card-div .gif-airplane {
    width: 70%;
    height: auto;
  }

  .card-div {
    align-items: center;
    justify-content: center;
  }

  .card-itens {
    width: 40% !important;
    padding: 0;
  }

  .close {
    margin: 10px 0;
  }

  .card-itens2 {
    width: 60%;
    height: auto;
    display: flex;
  }
  .card-itens2 .gif-airplane {
    width: 80% !important;
    height: auto;
  }
}
</style>
